/* Extra large devices (large laptops and desktops, 1200px and up) */
@media
only screen
and (min-width: 451px)
{
    .desktop {}
    .small-screen {
        display: none !important;
    }
    .tiny-screen {
        display: none !important;
    }

    .alternative-footer{
        display: none;
        visibility: hidden;
    }

}

@media
only screen
and (min-width: 451px)
and (max-width: 751px)
    /* 150% zoom  */
{
    .landing-page-comander.small-screen {
        display: inline-flex !important;
        position: relative;
        width: 0px;
        max-width: 0px !important;
        padding: 0px;
        margin: 0px !important;
        right: calc( var(--desktop-mode-betwen-margin) * 6 );
        left: 50vw;
        top: 4rem;
        flex-direction: row !important;
        align-self: flex-start;
        min-width: 0px !important;
        visibility: visible !important;
    }
}


@media
only screen
and (min-width: 1216px)
and (max-width: 1376px)
    /* 150% zoom  */
{
    .landing-page-comander {
        display: inline-flex !important;
        position: relative;
        width: 0px;
        max-width: 0px !important;
        padding: 0px;
        margin: 0px !important;
        /*right: 300px;*/
        /*right: -58vw;*/
        /*left: calc( 100vw - 1110px + var(--desktop-mode-betwen-margin) * 6 );*/
        /*right: calc( var(--desktop-mode-betwen-margin) * 12 );*/

        /*left: 58vw;*/
        /*left: -16vw;*/
        right: 0px !important;

        top: 4rem;
        flex-direction: row !important;
        align-self: flex-start;
        align-items: flex-start;
        align-content: flex-start;

        min-width: 0px !important;
        margin-top: -650px !important;
    }
}

@media
only screen
and (min-width: 1296px)
and (max-width: 1375px)
    /* 150% zoom  */
{
    .landing-page-comander {
        display: inline-flex !important ;
        position: relative !important;
        width: 0px !important;
        max-width: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
        /*right: 300px;*/
        /*right: -58vw;*/
        /*left: calc( 100vw - 1110px + var(--desktop-mode-betwen-margin) * 6 );*/
        /*right: calc( var(--desktop-mode-betwen-margin) * 12 );*/

        /*left: 58vw;*/
        /*left: -16vw;*/
        right: calc( 20px + var(--desktop-mode-betwen-margin) * 6 ) !important;

        top: 4rem !important;
        flex-direction: row !important;
        align-self: flex-start !important;
        align-items: flex-start !important;
        align-content: flex-start !important;

        min-width: 0px !important;
    }
}


@media
only screen
and (min-width: 1376px)
{ /* pre 150% zoom */

    .landing-page-comander {
        display: inline-flex !important ;
        position: relative !important;
        width: 0px !important;
        max-width: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
        /*right: 300px;*/
        /*right: -58vw;*/
        /*left: calc( 100vw - 1110px + var(--desktop-mode-betwen-margin) * 6 );*/
        /*right: calc( var(--desktop-mode-betwen-margin) * 12 );*/

        /*left: 58vw;*/
        /*left: -16vw;*/
        right: calc( 20px + var(--desktop-mode-betwen-margin) * 6 ) !important;

        top: 4rem !important;
        flex-direction: row !important;
        align-self: flex-start !important;
        align-items: flex-start !important;
        align-content: flex-start !important;

        min-width: 0px !important;
    }
}

