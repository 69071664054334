 .list-group-item {
     position: relative;
     display: block;
     padding: 0.75rem 1.25rem;
     background-color: #fff;
     border-color: white !important;
     border-bottom: 2px solid rgb(216, 214, 214) !important;
 }

 .cardheader {
     padding: 0.75rem 1.5rem;
     margin-bottom: 0;
     background-color: #fff;
     border-bottom: 2px solid rgb(216, 214, 214) !important;
     border-bottom-style: solid;
 }

 .colSpace {
     /* position: relative;
     width: 100%;
     padding-right: 10px !important;
     padding-left: 10px !important; */
 }
