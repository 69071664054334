.genderGap-top-card-part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 2rem;
    margin-right: 2rem;
}

.genderGap-top-card-part > DIV {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}


.open-answer-area {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    /*background: #f005;*/
}

.open-answer-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    /*background: #f0f5;*/
}

.open-answer-audit {
    flex: 1;
    /*background: #00fa;*/
}

.single-open-answer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*background: #0f08;*/
    margin-bottom: 2rem;
    justify-content: space-between;
}

.open-answer-content > DIV,
.single-open-answer-text {
    min-width: 320px;
    display: inline-flex;
}

.open-answer-content > INPUT,
.open-answer-content > TEXTAREA,
.single-open-answer-value {
    max-width: 320px;
}


.status-tag {
    max-height: 20px;
    line-height: 15px;
    margin-right: 1rem;
    margin-left: 0rem;
    color: #fff;
}


.preventive-question-helper-container {}

#preventive-question-bottom-container {
    display: flex;
    flex-direction: row;
    /*justify-content: flex-end;*/
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.preventive-question-button {
    margin-left: 1rem;
}

.modal-in-wait > DIV {
    background: #bbb;
}


.table-simulated {
    display: flex;
    flex-direction: column;
}

.table-simulated > .table-row {
    display: flex;
    flex-direction: row;
}
.table-simulated > .table-row:nth-child( even ) { background: #e8f5ff; }
.table-simulated > .table-row:nth-child( odd ) { background: #ffffff; }

.table-simulated > .table-row > .table-cell {
    flex: 1;
    display: flex;
    align-items: center;
}

.table-simulated > .table-row > .table-cell-height {
    min-height: 40px;
    padding: 10px;
}

.table-simulated > .table-row > .table-header-column {
    font-weight: bold;
}

