
.editable-q-a-element {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;


	/* aggiustamento a causa dell'edit di SRTool */
	margin-left: 0px !important;
	position: relative;
	left: 0px !important;

}


.editable-answer-container {
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/*width: 75%;*/
	padding-left: 0px;
}

.editable-single-answer {
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-radius: 5px;
	background: #fff8;
}

.editable-single-answer > DIV {
	margin-right: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-width: 40px;
}


.search-container {
	position: relative;
	left: calc( 100% - 520px );
}


/*  modale  */
.question-edit-modal-header-container > H5 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.question-code-label {
	color: #18f;
	margin-left: .5rem;
}


.lan-block {
	display: flex;
	flex-direction: column;
}

.lan-header {
	flex-direction: row;
	display: inline-flex;
	/*width: 90px;*/
	/*justify-content: space-between;*/
	justify-content: flex-start;
	margin-bottom: 0.25rem;
}

.lan-flag-container {
	width: 26px;
	margin-right: 0.5rem;
	position: relative;
	top: -3px;
}

.lan-flag-container > IMG {
	width: 100%;
	border-radius: 3px;
}

.lan-name {
	font-weight: bold;
}

.lan-editor-container {
	/*height: 20vh;*/
}

.lan-editor {
	height: inherit !important;
	margin-bottom: 2rem;
}

.lan-editor > DIV {
	height: inherit !important;
}

.lan-editor > DIV > DIV.CodeMirror {
	height: inherit !important;
}

.lan-editor > DIV > DIV.CodeMirror > DIV.CodeMirror-scroll {
	/*height: inherit !important;*/
	/*width: calc( 100% + 2rem );*/
	min-height: 16vh !important;
}


.edit-question-in-wait {
	background: #0008;
	top: -71px;
	left: 0px;
	width: 100%;
	height: calc( 100% + 71px );
}

.edit-question-in-wait > DIV {
	width: 75px;
	height: 75px;
	font-size: 2rem;
	color: #1f8c !important;
}

/*  per tab */

.cp-1 {
	/*background: #1f82 !important;*/
}

.cp-2 {
	/*background: #f811 !important;*/
}

.cp-3 {
	/*background: #18f2 !important;*/
}

.cpa-1:hover {
	background: #1f85 !important;
}

.cpa-2:hover {
	background: #f813 !important;
}

.cpa-3:hover {
	background: #18f5 !important;
}




/*
.guidelinee-icon {
	font-size: 1.5rem;
	color: var(--blue);
	cursor: pointer;
	justify-content: flex-end;
	display: flex;
}
.guidelinee-icon:hover {
	color: var(--success);
}
*/

.editable-q-a-comander-container {
	margin-left: 1rem;
}

.editable-q-a-comander-container.siscoring {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	min-width: 100px;
}

.editable-q-a-comander-container > I {
	font-size: 1.5rem;
	color: var(--blue);
	cursor: pointer;
	justify-content: flex-end;
	display: flex;
}

.editable-q-a-comander-container > I:hover {
	color: var(--success);
}


.guidelinees-icon {
	color: var(--blue);
	font-size: 1.25rem;
	margin-right: 0.5rem;
	margin-top: -0.1rem;
	cursor: pointer;
}

.guidelinees-icon:hover {
	color: var(--success);
}
.guidelinees-icon:focus {
	color: var(--primary);
}
.guidelinees-icon:active {
	color: var(--red);
}
.guidelinees-icon.big {
	font-size: 1.8rem;
	margin-top: -0.5rem;
	margin-right: 0px;
	margin-left: 0.75rem;
}

.in-deleting-wait {
	opacity: 0.2;
	font-style: italic;
}


.edit-question-modal-comander-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-width: 300px;
	align-items: center;
}

.edit-question-modal-comander-container > DIV {
	min-width: 150px;
}

.edit-question-modal-footer {

}

.edit-question-modal-comander-container > LABEL,
.edit-question-modal-footer > LABEL {
	margin-right: 1rem;
	margin-left: 1rem;
}

.edit-question-modal-comander-container > LABEL {
	margin-bottom: 0px;
}


.edit-question-modal-footer > INPUT {

}


.new-edit-question {
	top: -67px;
	height: calc( 100% + 67px );
}



.tab-pane-content {
	padding: 2rem;
}

.kpi-content-width{
	width: 150px !important;
}

.tab-pane-row {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.tab-pane-left {
	min-width: calc(60% - 1rem) !important;
}

.tab-pane-row > DIV {
	/*display: flex;*/
	/*flex-direction: row;*/
	min-width: calc(40% - 1rem);
}


.tab-pane-row > DIV > DIV {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.25rem;
	padding-left: 1rem;
	border-radius: 5px;
	cursor: pointer;
}



.tab-pane-row > DIV > DIV:hover {
	background: #aaa3;
}

.tab-pane-left > DIV:hover {
	background: transparent !important;
}

.tab-pane-row-select {
	min-width: 217px;
}






