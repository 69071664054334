/* Small Screen  * /
@media
only screen
and (min-width: 451px)
and (max-width: 1024px)
{
    .desktop {
        display: none !important;
    }
    .small-screen {}
    .tiny-screen {
        display: none !important;
    }

    .mobile-safe-area {
        display: none;
        visibility: hidden;
    }

    .landing-page-comander.small-screen {
        visibility: visible;
        display: inline-flex !important;
        position: absolute;
    }


    .alternative-footer{
        display: none;
        visibility: hidden;
    }

    .content-container {
        justify-content: space-between;
    }

    FOOTER {

        margin-top: 0px !important;
    }

    .signup-panel-company-button-disabled {
        margin-top: 0rem;
    }

    .signup-panel-profile-title {
        color: #fff;
        line-height: 50px !important;
        font-size: 42pt !important;
        width: 640px !important;
        align-self: center;
        margin-top: 2.5rem;
    }

    .signup-panel-profile-title-signup > P {
        color: #fff;
    }


    .sign-landing-page.small-screen {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: calc(100vh - 60px);
    }

    .sign-landing-page.small-screen > DIV.top {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        align-items: center;
    }
    .sign-landing-page.small-screen > DIV.top > IMG {
        width: 140px !important;
    }

    .sign-landing-page.small-screen > DIV.middle {
        flex: 3;
        overflow: auto;
        padding: 2rem;
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;

        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
    }
    .sign-landing-page.small-screen > DIV.middle::-webkit-scrollbar {
        display: none;
    }

    .sign-landing-page.small-screen > DIV.middle > DIV {
        min-width: 300px;

        max-width: 400px;
        margin: 1rem;
        padding: 2rem;
    }
    .sign-landing-page.small-screen > DIV.middle > DIV.large-block {
        min-width: 300px;

        max-width: 760px;
        margin: 1rem;
        padding: 2rem;
    }



    .sign-landing-page.small-screen > DIV > DIV > P,
    .sign-landing-page.small-screen > DIV > DIV > STRONG > P
    {
        text-align: center;
    }

    .sign-landing-page.small-screen > DIV.bottom {
        flex: 1;
        background: #fff2;
        align-self: center;
        align-content: center;
        align-items: center;
        justify-content: space-around;
        display: flex;
        flex-direction: column;
        width: 100vw;
        text-align: center;
        min-width: 100vw;
        max-width: 100vw;
    }



    .panel {
        background: #fff;
        margin: 2rem;
        margin-top: 4rem;

        padding: 3rem;
        border-radius: 10px;
        flex: 1;
        max-width: 640px;
        align-self: center;

    }


    #signup-panel-profile-footer {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        align-items: center;
        border: solid 0px transparent;
    }

    #signup-panel-profile-footer > SPAN {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        text-align: center;
    }

    .signup-panel-profile-logo-container {
        margin-top: 3rem;
        min-height: 25vh;
        justify-content: space-between;
    }


    .signup-panel-profile-button {

        align-self: center;
    }

    .signup-panel-profile-button-step-2 {
        margin-top: 0rem;
    }

    .signup-panel-profile-button-landing {
        margin-top: 1rem;
    }

    .sign-landing-page-bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: center;
    }

    #arbsbpa-logo {
        align-self: center;
    }

    .sign-landing-page-bottom > SPAN {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        text-align: center;
    }


    .signup-panel-profile-button-disabled-step-3 {
        margin-top: 5rem;
    }

}
*/


/* Small Screen  */
@media
only screen
and (min-width: 451px)
and (max-width: 810px)
{

    .landing-page-comander {
        display: none;
        visibility: hidden;
    }


    .landing-page-comander.small-screen {
        display: inline-flex !important;
        position: relative;
        width: 0px;
        /*max-width: 0px !important;*/
        padding: 0px;
        margin: 0px !important;
        /*right: calc( var(--desktop-mode-betwen-margin) * 6 );*/
        left: 50vw;
        top: 4rem;
        flex-direction: row !important;
        align-self: flex-start;
        /*min-width: 0px !important;*/
        visibility: visible !important;
    }

}


@media
only screen
and (min-width: 811px)
and (max-width: 1220px)
{

    .landing-page-comander {
        display: none;
        visibility: hidden;
    }


    .landing-page-comander.small-screen {
        display: inline-flex !important;
        position: relative;
        width: 0px;
        /*max-width: 0px !important;*/
        padding: 0px;
        margin: 0px !important;
        /*right: calc( var(--desktop-mode-betwen-margin) * 6 );*/
        left: 72vw;
        top: 4rem;
        flex-direction: row !important;
        align-self: flex-start;
        /*min-width: 0px !important;*/
        visibility: visible !important;
    }
}

