.password-eye {
	color: #06fc;
	float: right;
	margin-top: -29px;
	margin-right: 8px;
	font-size: 16px;
	cursor: pointer;
	position: relative;
}

.loading-wait-postfix {
	font-style: italic;
	font-size: smaller;
	color: #ffa9a9;
}
.loading-wait-postfix2 {
	font-style: italic;
	font-size: smaller;
}

.select-placeholder {
	font-style: italic;
	color: #b3b3b3;
}

.error-message-label {
	/*color: #fb6363;*/
	color: #f88;
	float: right;
	font-size: 12px;
	font-style: italic;
	margin-right: 5px;
}
