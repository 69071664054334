
.chart-switch-on {}

.chart-switch-off {}


.chart-radio-on {}

.chart-radio-off {}

.chart-filter-select {}

.chart-filter-select > SPAN {
    /*background-color: #f00;*/
}

.chart-filter-select > DIV > DIV {
    /*background-color: #fF0;*/
    /*overflow: auto;*/
}

.chart-filter-no-data-label {
    justify-content: flex-end;
}
.chart-filter-no-data-label > SPAN {
    font-size: 0.85rem;
}
