/**
Color Policy per SRTool
 */

/* GENERAL ========================================================================================================== */
/* TAB  ------------------------------------------------------------------------------------------------------------- */
.general-tab {
    background-color: #D8E7FFAA !important;
}

.general-tab:hover {
    color: #2E3980;
    background-color: #D8E7FF !important;
    /*border-width: 2px;*/
    /*border-color: #3000FF;*/
}

.general-tab-active {
    color: #2E3980 !important;
    background-color: #D8E7FF !important;
    /*border-width: 1px !important;*/
    /*border-color: #304080 !important;*/
}

.react-select.general-select > div > div {
    background-color: #D8E7FF88;
}

/*  INFORMATIVA  ---------------------------------------------------------------------------------------------------- */
.general-light {
    background-color: #F2F6FF !important;
}

.general-light > div > div > div {
    background-color: #F2F6FFAA !important;
}



.react-select.general-light > div {
    background-color: #F2F6FFAA !important;
}


.general-tab-goal {
    background: #D8E7FF !important;
    border-color: #D8E7FF !important;
    box-shadow: 0px 2px 6px 0px #D8E7FF !important;
    color: #222 !important;
}









/* ECONOMIC ========================================================================================================= */
/* TAB  ------------------------------------------------------------------------------------------------------------- */
.economic-tab {
    background-color: #E9DEF5AA !important;
}

.economic-tab:hover {
    color: #5A2793;
    background-color: #E9DEF5 !important;
    /*border-width: 1px !important;*/
    /*border-color: #7A00FFFF !important;*/
}

.economic-tab-active {
    color: #5A2793 !important;
    background-color: #E9DEF5 !important;
}

.react-select.economic-select > div > div {
    background-color: #E9DEF588;
}

/*  INFORMATIVA  ---------------------------------------------------------------------------------------------------- */
.economic-light {
    background-color: #EFE9F4 !important;
}

.economic-light > div > div > div {
    background-color: #EFE9F4AA !important;
}

.react-select.economic-light > div {
    background-color: #EFE9F4AA !important;
}



.economic-tab-goal {
    background: #5A2793 !important;
    border-color: #5A2793 !important;
    box-shadow: 0px 2px 6px 0px #5A2793 !important;
    color: #fff !important;
}




/* ENVIRONMENTAL ==================================================================================================== */
/* TAB  ------------------------------------------------------------------------------------------------------------- */
.environment-tab {
    background-color: #C2E5D5AA !important;
}

.environment-tab:hover {
    color: #2CA175 !important;
    background-color: #C2E5D5 !important;
    /*border-width: 2px;*/
    /*border-color: #00FF80;*/
}

.environment-tab-active {
    color: #2CA175 !important;
    background-color: #C2E5D5 !important;
}

.react-select.environment-select > div > div {
    background-color: #C2E5D588;
}

.react-select.environment-select > div {
    background-color: #C2E5D588;
}



/*  INFORMATIVA  ---------------------------------------------------------------------------------------------------- */
.environment-light {
    background-color: #E5F8F0 !important;
}

.environment-light > div > div > div {
    background-color: #E5F8F0AA !important;
}

.environment-light.react-select > div > div > div {
    background-color: #E5F8F0AA !important;
}

.react-select.environment-light > div > div > div {
    background-color: #E5F8F0AA !important;
}

.react-select.environment-light > div {
    background-color: #E5F8F0AA !important;
}


.environment-tab-goal {
    background: #2CA175 !important;
    border-color: #2CA175 !important;
    box-shadow: 0px 2px 6px 0px #2CA175 !important;
    color: #222 !important;
}





/* SOCIAL =========================================================================================================== */
/* TAB  ------------------------------------------------------------------------------------------------------------- */
.social-tab {
    background-color: #FFE0D1AA !important;
}

.social-tab:hover {
    color: #FC6D29 !important;
    background-color: #FFE0D1 !important;
    /*border-width: 2px;*/
    /*border-color: #FF3030;*/
}

.social-tab-active {
    color: #FC6D29;
    background-color: #FFE0D1 !important;
}

.react-select.social-select > div > div {
    background-color: #FFE0D188;
}


/*  INFORMATIVA  ---------------------------------------------------------------------------------------------------- */
.social-light {
    background-color: #FFF3ED !important;
}

.social-light > div > div > div {
    background-color: #FFF3EDAA !important;
}


.react-select.social-light > div {
    background-color: #FFF3EDAA !important;
}



.social-tab-goal {
    background: #FC6D29 !important;
    border-color: #FC6D29 !important;
    box-shadow: 0px 2px 6px 0px #FC6D29 !important;
    color: #fff !important;
}





.requirement-color {
    background: #f5f5f5 !important;
}
