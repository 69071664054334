.rounded-modal > DIV {
    background: #0000 !important;
}

.rounded-modal-container {
    border-radius: 12px !important;
    overflow: hidden;
    min-height: 75vh !important;
}

