.test-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1rem;
    padding: 1rem;
}

#rawTableData {
    width: 100%;
    border: solid 0px transparent;
    background: #0001;
    margin-top: 1rem;
    height: calc( 100vh - 8rem );
    padding: 1rem;
}

.test-page-comanders {
    flex: 1;
}

.test-page-comanders-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 25px;
    align-self: center;
}

.test-page-comanders > DIV {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.test-page-tree {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
}

.test-page-result {
    flex: 2.5;
}

.test-page-result > DIV {
    border: solid 2px #aaa;
    width: 100%;
    height: calc( 100vh - 8rem );
    margin-top: 2rem;
    border-radius: 10px;
}

.test-page-error {
    color: #d00;
}

