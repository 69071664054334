.ggct-1 {
    fill: none;
}

.ggct-2 {
    clip-path: url(#clippath);
}

.ggct-3 {
    fill: #333;
}

.ggct-4 {
    fill: #575756;
}

.ggct-5 {
    clip-path: url(#clippath-1);
}

.ggct-6 {
    clip-path: url(#clippath-4);
}

.ggct-7 {
    clip-path: url(#clippath-3);
}

.ggct-8 {
    fill: url(#Sfumatura_senza_nome_14-2);
}

.ggct-9 {
    fill: url(#Sfumatura_senza_nome_23-2);
}

.ggct-10 {
    fill: url(#Sfumatura_senza_nome_14);
}

.ggct-11 {
    fill: url(#Sfumatura_senza_nome_23);
}
