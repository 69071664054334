@font-face {
    font-family: 'Raleway';
    src: url('../../../assets/fonts/Raleway-Medium.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: 'Lato';
    src: url('../../../assets/fonts/Lato-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}


#pdf-container {
    margin-top: 50px;
}

#pdf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/*
210 x 297 mm
*/
.pdf-page {
    min-width: 297mm;
    max-width: 297mm;
    min-height: 210mm;
    max-height: 210mm;
    box-shadow: 0px 5px 10px #0003;
    margin: auto;
    background-size: contain;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#siscoring-pdf-page_1 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/it/ScoreCard_it_01.png");
}
.lang_en-siscoring-pdf-page_1 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/en/ScoreCard_en_01.png") !important;
}

#siscoring-pdf-page_1 > SPAN {
    margin-top: 300pt;
    margin-left: 260pt;
    width: 570px;
    text-align: center;
}
#siscoring-pdf-page_1 > SPAN.wait-container {}
#siscoring-pdf-page_1 > SPAN.text-content {
    font-size: 14pt;
    font-weight: 100;
    display: inline-block;
    text-justify: inter-word;
    font-stretch: ultra-expanded;
}

#siscoring-pdf-page_2 {
    background-image: url( "https://siroad.it/assets/PdfSiScoring/it/ScoreCard_it_02.png");

}
.lang_en-siscoring-pdf-page_2 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/en/ScoreCard_en_02.png") !important;
}

#siscoring-pdf-page_2 > SPAN {
    margin-top: -200pt;
    margin-left: 260pt;
    width: 570px;
    text-align: center;
}


#siscoring-pdf-page_3 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/it/ScoreCard_it_03.png");
}
.lang_en-siscoring-pdf-page_3 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/en/ScoreCard_en_03.png") !important;
}


#siscoring-pdf-page_4 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/it/ScoreCard_it_04.png");
}
.lang_en-siscoring-pdf-page_4 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/en/ScoreCard_en_04.png") !important;
}


#siscoring-pdf-page_5 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/new/5.png");
}

#siscoring-pdf-page_6 {
    background-image: url("https://siroad.it/assets/PdfSiScoring/new/6.png");
}

.middle-line-text {
    font-size: 1.5pt;
    font-family: Lato;
}

#line-chart {
    min-width: 297mm;
    width: 297mm;
    max-width: 297mm;

    min-height: 210mm;
    height: 210mm;
    max-height: 210mm;

    position: relative;
    top: 247px;
    left: 3px;

}

#circleChart {
    position: absolute;
    top: 1050px;
}

#companyData {
    width: 260px;
    min-height: 200px;
    position: relative;
    top: 40px;
    left: -353px;
    display: flex;
    flex-direction: column;
    color: #fff;
}

.companyData-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2px;
    position: relative;
}

.companyData-element > LABEL {
    margin-bottom: 0px;
    font-weight: 700;
    text-shadow: 1px 3px 10px #0008;

    font-family: 'Raleway Medium', Lato, Verdana;
    font-size: 14px;


}

.companyData-element > SPAN {
    text-shadow: 1px 3px 10px #0008;

    font-family: var(--default-textes-font);
    font-size: 0.75rem;
    color: #fffd;
    text-shadow: 0px 2px 5px #2b303f;
}


#company-data-name {

}

#company-data-address {
    top: 15px;
}

#company-data-city {
    top: 45px;
}

#company-data-city {
    top: 30px;
}

#company-data-vat {
    top: 42px;
}

#company-data-release-date {
    top: 65px;
}




#sdg-values-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -230px;
    left: -275px;
    width: 370px;
}

.sdg-value-perc {
    margin-right: 65px;
    margin-bottom: 63px;
    width: 40px;
}


.block-perc {
    color: #fff;
    font-size: 1.2rem;
    position: relative;
    font-family: var(--default-textes-font);
}

#envAmbPerc {
    left: -395px;
    top: 80px;
}

#envECPerc {
    left: -395px;
    top: 195px;
}

#envRRPerc {
    left: -395px;
    top: 300px;
}






#socCond {
    left: -35px;
    top: -100px;
}

#socRCC {
    left: -35px;
    top: -35px;
}

#socSQ {
    left: -35px;
    top: 170px;
}






#govSTR {
    left: 322px;
    top: -75px;
}
#govEt {
    left: 325px;
    top: 50px;
}

#govInn {
    left: 325px;
    top: 140px;
}






