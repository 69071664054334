.logo-container-srtml {
    width: 30px;
    display: inline-block;
}
.srtml{fill:none;}
.srtml-2{fill:#333;}
.srtml-3{fill:#c6c6c6;}
.srtml-4{clip-path:url(#clip-path);}
.srtml-5{clip-path:url(#clip-path-2);}
.srtml-6{fill:#fff;}
