.tree-node-header-container {
    height: auto !important;
    display: flex;
    flex-direction: column;
}

.tree-node-header {
    height: auto !important;
    display: flex;
    flex-direction: row;
}

.tree-node-header-body {
    color: #666;
    cursor: pointer;
    font-size: 1rem;
}

.tree-node-header-commands {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    justify-content: flex-end;
    padding-left: 5rem !important;
}

.tree-node-header-single-command {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 5rem !important;
}


.tree-node-controllers {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.tree-node-controllers-informative {
    justify-content: space-between;
}

.tree-node-controllers-group {
    display: flex;
    flex-direction: column;
    /*max-width: 500px;*/
    min-width: 300px;
    width: 25%;
    justify-content: flex-start;
}

.tree-node-controller {
    margin-bottom: 0.5rem;
    min-height: 30px;
}

.tree-node-inline-multi-controller {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.tree-node-controller-label {
    font-weight: bold;
    min-width: 100px;
    max-width: 150px;
    width: 7rem;
}

.tree-node-controller-select {
    width: 60%;
    display: inline-block;
}


.tree-node-controller-button {
    border-radius: 5px !important;
    padding: 0px !important;
    padding-right: 0px;
    padding-left: 0px;
    padding-left: 1rem !important;
    padding-right: 1.25rem !important;
}


.tree-node-controller-text-button {
    position: relative;
    top: -2px;
}

.tree-node-controller-text-button-empty {
    font-style: italic;
}

.tree-node-controller-text-button-icon {
    font-size: 1.3rem;
    margin-right: 0.75rem;
}


.hundred-three-button {
    /*padding: 0px !important;*/
    /*width: 100px;*/
    /*height: 25px;*/
    /*margin-left: 1rem;*/
    /*margin-right: 1rem;*/

    margin-right: 1rem;
    height: 24px;
    padding: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
    min-width: 80px;
}


.tree-node-controller-goal-text-button {
    height: 30px !important;
    position: relative;
    top: 2px;
}



.tree-node-chatper {
    height: auto !important;
}



.over-waiting-panel {
    /*position: absolute;*/
    position: fixed;
    top: 0px;
    background: #0008;
    z-index: 99;
    width: 100% !important;
    height: 100% !important;
    left: 0px;

    display: flex;
    flex-direction: row;
}

.over-waiting-panel-spinner {
    width: 100vw !important;
    height: 100vh !important;
    font-size: 1.25rem;
    z-index: 100;
}

.over-waiting-panel-spinner > DIV {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    color: #333 !important;
}

.over-waiting-panel-spinner > SPAN {
    color: #333 !important;
    font-size: 2rem !important;
    margin-left: 2rem;
}


.over-waiting-panel-white {
    /*position: absolute;*/
    position: fixed;
    top: 0px;
    background: #fff8;
    z-index: 99;
    width: 100% !important;
    height: 100% !important;
    left: 0px;

    display: flex;
    flex-direction: row;
}

.over-waiting-panel-white-spinner {
    width: 100vw !important;
    height: 100vh !important;
    font-size: 1.25rem;
    z-index: 100;
}

.over-waiting-panel-white-spinner > DIV {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    color: #333 !important;
}

.over-waiting-panel-white-spinner > SPAN {
    color: #333 !important;
    font-size: 2rem !important;
    margin-left: 2rem;
}


.tag {
    height: 30px;
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    line-height: 8px;
    text-align: center;
    margin-left: 1rem;
    font-size: 0.8rem;
}

.not-material {
    background: #0001;
    width: 12rem;
    font-style: italic;
}

.goal-icon-component {
    width: 30px;
    display: inline-block;
    position: relative;
    top: 0px;
    margin-right: 0px;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 3px;
}


.goal-button-disabled {
    background: #6C757D !important;
    border-color: #6C757D !important;
    box-shadow: 0px 2px 6px 0px #6C757D !important;
    color: #ddd !important;
}




/*  dateTree   ------------------------------------------------------------------------------------------------------ */
.tree-node-controller-group-textarea-container {
    flex: 1;
    max-width: 200px;
    min-width: 150px;
}

.tree-node-controller-group-textarea-container2 {
    flex: 1;
    max-width: 100%;
    min-width: 150px;
}

.tree-node-controller-textarea {
    display: flex;
    flex-direction: column;

    width: 320px;
    height: 200px;
}

.tree-node-controller-textarea2 {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 200px;
}


.tree-node-controller-textarea > DIV {
    height: 100%;
}

.tree-node-controller-textarea > DIV > TEXTAREA {
    height: 100% !important;
}

.tree-node-controller-date {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}


.small-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    align-self: flex-end;
}


.tree-node-data-entry-value-box-component-textarea {
    /*max-height: 80px !important;*/
    height: 80px !important;
}
.tree-node-data-entry-value-box-component-textarea2 {
    /*max-height: 80px !important;*/
    height: 160px !important;
}

.tree-node-data-entry-value-box-component-input {
    position: relative;
    top: -1rem;
}

TEXTAREA {
    border-radius: 5px !important;
}


.srtool-note-icon {
    /*font-size: 1.25rem;*/
    margin-right: 0.5rem !important;
    font-size: 0.25rem;
    width: 40px;
    display: inline-block;
    margin-top: 0px;
    position: relative;
    top: -5px;
    min-width: 40px;
    max-width: 40px;
}

.srtool-noted-icon {
    margin-right: 0.5rem !important;
    font-size: 0.25rem;
    width: 40px;
    display: inline-block;
    margin-top: 0px;
    position: relative;
    top: -20px;
    min-width: 40px;
    max-width: 40px;
}


.srtool-info-attachment {
    position: relative;
    top: -10px;
    margin-right: 1rem;
}


.chart-nodata-notific {
    font-style: italic;
    text-transform: none;
    float: right;
    margin-right: 1rem;
    color: #d31;
}

.no-chart-data-card {
    width: 40vw;
}

.srtool-attachment-notific-error {
    padding: 1rem;
}



.srtool-question-history-button {
    font-size: 1.5rem;
    margin-top: -4px;
    margin-right: 1rem;
    color: #727cf5;
    width: 40px;
}






/* Copy configuration collect ----------------------------------------------------------------------- */
.new-collect-comander-container {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1rem !important;
}


.new-collect-comander-container > SPAN {
    margin-right: 1rem !important;
}

#new-collect-comander-container-select {
    width: 200px;
}

.new-collect-comander-container-waiting-wheel {
    font-size: 1rem;
    width: 20px !important;
    height: 20px !important;
    margin-left: 1rem;
}
/* ---------------------------------------------------------------------- Copy configuration collect  */




.warningMessage {
    color: var(--red);
}
