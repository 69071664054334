#siscoring-waiting-modal-main-page {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 50vh;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

#siscoring-waiting-modal-main-page > IMG {
    /*width: 80px;*/
    height: 60px;
}


.siscoring-waiting-modal-main-text {
    margin: 2rem;
}

.siscoring-waiting-modal-progress-container {
    width: 80%;
}

#siscoring-waiting-modal-progress {
    height: 20px;
    margin-bottom: 0px !important;
    /*background-color: #1af; /* colore sfondo */
}
.progress-bar {
    background-color: #1af !important; /* colore barra */
}

.siscoring-waiting-modal-progress-value {
    position: relative;
    top: -20px;
    text-align: center;
}

.white-text {
    color: #fff;
}

.siscoring-waiting-modal-text {

}

.siscoring-waiting-modal-text > H5 {
    text-align: center;
}

.siscoring-waiting-modal-text > SPAN {
    text-align: center;
    display: inline-flex;
}

.siscoring-waiting-modal-footer {}

.siscoring-waiting-modal-footer > SPAN {
    color: #18f;
    text-transform: capitalize;
    cursor: pointer;
    margin-left: 5px;
}

.siscoring-waiting-modal-space-download-button {
    width: 100%;
    height: 80px;
}

.siscoring-waiting-modal-download-button {
    background: var(--success) !important;
    outline: none !important;
    border: solid 0px transparent !important;
    box-shadow: none !important;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.siscoring-waiting-modal-download-button > SPAN > I {
    margin-left: 0.75rem;
    position: relative;
    top: -2px;
    font-size: 14px;
}

/*
.siscoring-waiting-modal-download-button > SPAN > DIV {
    width: 16px;
    display: inline-block !important;
    margin-left: 0.8rem;
}

.download-icon-c {
    fill: #fff;
}
*/
