.gg-1{
	fill:#fff;
}

.gg-2{
	clip-path:url(#clippath-1);
}

.gg-3{
	clip-path:url(#clippath-4);
}

.gg-4{
	clip-path:url(#clippath);
}

.gg-5{
	fill:none;
}

.gg-6{
	clip-path:url(#clippath-3);
}

.gg-7{
	fill:#c6c6c6;
}
