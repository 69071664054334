.super-admin-dashboard-main-table > TABLE > THEAD > TR > TH {
	height: 256px !important;
	vertical-align: top !important;
}


.super-admin-dashboard-main-table > TABLE > TBODY > TR > TD {
	height: 120px !important;
	vertical-align: top !important;
	min-width: 255px !important;
}


.command-column-header {
	display: inline-flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 0.5rem;
	width: 100%;
	align-items: flex-start;
	align-content: flex-start;
}

.command-column-header > I {
	margin-left: 1rem;
}

.command-column-header > I:hover {
	color: #f00;
}


.sadashboard-datepicker-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sadashboard-datepicker-label {
	align-self: center;
	margin-left: 30%;
	margin-right: 10px;
}


.sadashboard-switch {
	/*display: inline-flex !important;*/
	/*align-items: center;*/
	position: absolute !important;
	right: 275px;
	top: 8px;
}

.sadashboard-switch > LABEL {
	width: 110px;
}

.sadashboard-year-input {
	max-width: 95px;
	min-width: 90px;
}

.green-sadashboard-year-input {
	border: solid 2px #0acf97 !important;
	color: #ccc !important;
}

.sadashboard-two-table {
	display: flex;
	flex-direction: row;
	/*height: 65vh;*/
}

.sadashboard-table-2 {
	/*overflow-x: auto;*/
	width: 85%;
}

.sadashboard-table-1 > DIV.react-bootstrap-table-pagination {
	display: none !important;
}

.sadashboard-table-2 > DIV {
	/*width: 100vw;*/
	overflow-x: auto;
}

.sadashboard-table-2 > DIV.react-bootstrap-table-pagination {
	width: 70vw;
	overflow-x: unset;
}

.set-year-button {
	margin: 10px 8px;
	position: relative;
	top: -3px;
}

