/* Extra small devices (phones, 450px and down) */
@media
only screen
and (max-width: 450px)
and (orientation: portrait)
{


    :root {
        --mobile-safe-area-height: 6vh;
    }


    .desktop {
        display: none !important;
    }
    .small-screen {
        display: none !important;
    }
    .tiny-screen {}


    .content-container {
        background: transparent !important;
        min-height: calc( 100vh - var(--mobile-safe-area-height) *4 );
    }

    .carousel > DIV.curtain {
        display: block;
        visibility: visible;
        height: 100vh;
        background: #0009;
        z-index: 5;
        position: absolute;
        min-width: 100vw;
    }


    .mobile-safe-area {
        display: block;
        visibility: visible;
        height: 11vh;
    }

    .landing-page-comander.small-screen {
        display: none;
        visibility: hidden;
        left: 80vw;

    }

    .landing-page-comander.tiny-screen {
        position: relative;
        left: 80vw;
        top: -289px;
    }

    .landing-page-comander.signin.tiny-screen {
        position: relative;
        left: 80vw;
        top: 0px;
    }


    FOOTER {
        display: none !important;
        visibility: hidden !important;
    }

    .alternative-footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: center;
        visibility: visible;
        /*margin-bottom: -120px !important;*/
        margin-bottom: -105px !important;
    }

    .alternative-footer > DIV {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-self: center;
    }

    .alternative-footer > DIV > SPAN {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        color: #a4a09c;
    }

    .alternative-footer > DIV > SPAN > A {
        font-family: Raleway, Verdana;
        color: #fff8;
    }

    .alternative-footer > DIV > SPAN > A:hover {
        color: #fff !important;
        text-decoration: underline !important;
    }

    .arblogo-component {
        align-items: center;
        /*margin-top: 6rem;*/
        margin-bottom: 2rem;
    }

    .signup-panel-profile-title {
        font-size: 30pt !important;
        color: #fff;
    }

    .signup-panel-profile-logo-container {
        height: 35vh;
        min-height: 35vh;
        max-height: 35vh;
    }

    .panel {
        margin: 0px;
        border-radius: 0px;
        width: 100%;
        padding: 1rem;
        min-height: 75vh;
    }

    .panel-step-2 {
        min-height: 100vh;
    }



    .sign-landing-page.tiny-screen {
        padding: 0rem;
        padding-top: 2.5rem;
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
        min-height: calc( 80vh - var(--mobile-safe-area-height) *4 );
        overflow: hidden;
    }
    .sign-landing-page.tiny-screen > DIV.top {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        height: calc( 80vh - var(--mobile-safe-area-height) *4 );
        min-height: calc( 80vh - var(--mobile-safe-area-height) *4 );
        max-height: calc( 80vh - var(--mobile-safe-area-height) *4 );
        overflow: scroll;
        overflow-x: hidden !important;
    }


    .sign-landing-page.tiny-screen > DIV.top > DIV {
        padding: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .sign-landing-page.tiny-screen > DIV.bottom {
        /*height: calc(20vh - 60px);*/
        /*min-height: calc(20vh - 60px);*/
        /*max-height: calc(20vh - 60px);*/
        background: #fff2;
        width: 100vw;
        align-self: center;
        margin-bottom: 25px !important;
    }

    .sign-landing-page.tiny-screen > DIV.bottom > BUTTON {
        margin-top: 10px !important;
    }

    .sign-landing-page.tiny-screen > DIV.bottom.step2 {
        height: calc(20vh - 10px);
        min-height: calc(20vh - 10px);
        max-height: calc(20vh - 10px);
        background: #fff2;
        width: 100vw;
        align-self: center;
        display: flex;
        flex-direction: column;
    }


    .signup-panel-profile-container {
        height: auto;
    }

    .sign-landing-page.tiny-screen > DIV.bottom.step2 > BUTTON {
        align-self: center;
    }

    DIV.signup-panel-company-button-container {
        justify-content: space-between;
        align-items: center;
    }

    DIV.signup-panel-company-button-container > BUTTON.signup-panel-company-button-disabled {
        margin-top: 0rem !important;
    }

    .sign-landing-page.tiny-screen > DIV.top > IMG,
    .sign-landing-page.tiny-screen > DIV.top > DIV > IMG
    {
        width: 150px;
        margin-top: 0rem;
        margin-bottom: 4rem;
    }

    .sign-landing-page.tiny-screen > DIV.top > DIV > DIV > P {
        font-size: 11pt;
        word-wrap: anywhere;
        white-space: normal;
    }


    #sign-landing-page-title {
        font-family: BebasNeue;
        font-size: 3.5rem;
        margin-top: 0rem;
        margin-bottom: 0rem;
        text-align: center;
        line-height: 3.5rem;
        max-width: 220px;
        align-items: center;
        display: inline-flex;
        padding: 0px;
    }

    /*.sign-landing-page.tiny-screen > DIV.middle,*/
    .sign-landing-page.tiny-screen > DIV.top > DIV.large-block > P
    {
        text-align: center;
    }

    #sirating-logo {
        width: 120px;
    }


    .signup-panel-profile-element {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }


    .signup-panel-profile-form-container {
        display: flex;
        flex-direction: column;
    }

    .signup-panel-profile-form-container > DIV {
        width: 100%;
    }

    #signupProfile_accept {
        position: relative;
    }

    .signup-panel-profile-button {
        /*align-self: flex-end;*/
        align-self: center;
        margin-bottom: 1rem;
        margin-top: 20px;
        margin-left: 53%;
    }

    #signup-panel-profile-alternative > SPAN {
        color: #ce5a31;
        margin-top: 1.5rem;
        margin-bottom: 0.2rem;
    }

    .signup-panel-profile-title-signup {
        color: #fff;
        font-size: 22pt !important;
    }

    .signup-panel-profile-button-step-2 {
        margin-top: 1rem;
    }

    .signup-panel-profile-button-landing {
        margin-top: 1rem;
    }


    .signup-panel-company-form-container {
        display: flex;
        flex-direction: column;
    }

    .signup-panel-company-form-container > DIV {
        width: 100%;
    }

    .signup-panel-profile-button-step-3 {
        margin-top: 0px;
        margin-bottom: 0px;
    }


    .tiny-screen-access-button {
        margin-left: 0px;
    }

}

@media
only screen
and (max-height: 450px)
and (orientation: landscape)
{
    .desktop {
        display: none !important;
    }
    .small-screen {
        display: none !important;
    }
    .tiny-screen {}
}

