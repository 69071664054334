.logo-container-ssml {
    width: 30px;
    display: inline-block;
}

.si-scoring-menu-logo-e {
    fill: none;
}

.si-scoring-menu-logo-f {
    fill: #fff;
}

.si-scoring-menu-logo-g {
    fill: #c6c6c6;
}

.si-scoring-menu-logo-h {
    clip-path: url(#c);
}

.si-scoring-menu-logo-i {
    clip-path: url(#b);
}

.si-scoring-menu-logo-j {
    clip-path: url(#d);
}
