.tabella {
    overflow-y: auto;
    overflow-x: hidden;
    height: 45vh
}

.colPadding > TR > TH {
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.headerTabella{
    background: #dad8fd;
}

.table2 {
    overflow-y: auto ;
    overflow-x: hidden ;
    height: 50vh
}

.rigascura {
    background-color: ghostwhite;
}

