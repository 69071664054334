.table th, .table td {
    padding: 0.5rem  !important;
}

#pippo{

    background-color: red !important ;
    color: green !important ;
}

.pippo2{

    background-color: red !important ;
    color: green !important ;
}

.rightSpaceList{

    margin-left: 10px;
}
.popover{

    max-width: 1500px !important;
}


.table th, .table td {
    padding: 0.95rem;
    vertical-align: middle !important;
    border-top: 1px solid #dad8fd ;
}

.tableBorderLeft {
    border-left: 1px solid  rgb(0, 170, 255) !important;
}

.tableBorderLeft2 {
    border-left: 1px solid  aliceblue !important;
}

.tableBorderTop {
    /*border-top: 1px solid  rgb(0, 170, 255) !important;*/
}