.recap-status-icon-c{
    stroke-width:3.67px;
}
.recap-status-icon-c,.recap-status-icon-d,.recap-status-icon-e,.recap-status-icon-f,.recap-status-icon-g{
    fill:none;
    stroke:#98a6ad;
}
.recap-status-icon-c,.recap-status-icon-e,.recap-status-icon-f{
    stroke-miterlimit:10;
}
.recap-status-icon-d{
    stroke-width:4.58px;
}
.recap-status-icon-d,.recap-status-icon-g{
     stroke-linejoin:round;
}
.recap-status-icon-e,.recap-status-icon-f{
    stroke-width:4.79px;
}
.recap-status-icon-e,.recap-status-icon-g{
    stroke-linecap:round;
}
.recap-status-icon-g{
    stroke-width:3.59px;
}
