
.question-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 440px;
}

.question-header-main {
    flex: 9;
    padding-right: 2rem;
    cursor: pointer;
    max-width: 1150px;
}

.question-header-com {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    max-width: 220px;
}


.question-header-top {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.question-header-top-main {
    flex: 1;
    display: flex;
    flex-direction: row;
}



.question-header-content {}



.question-header-top > STRONG {
    margin-right: 1rem;
}


.question-header-bottom {
    flex: 1;
}



.answer-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px !important;
}

.answer-body-header {
    flex: 4;
}

.answer-body-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.answer-body-content-choiches {
    display: flex;
    flex-direction: column;
}

.sirating-admin-choiche-area {
    /*max-width: 1200px;*/
    /*width: calc(100% - 70px);*/
}
.sirating-admin-choiche-area2 {
    z-index: 1;
}

.answer-body-content-audit {
    background: #0001;
    flex: 2;
    padding: 1rem;
    /*margin-top: 1rem;*/
    border-radius: 5px;
    /*overflow: auto;*/
}


.audit-panel-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #18f;
}


.non-compliant-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.non-compliant-container-status {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.non-compliant-container-status-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.non-compliant-container-data {

}





.non-compliant-container > DIV {
    /*width: 100%;*/
    /*margin-left: 1rem;*/
}

.non-compliant-container > DIV > DIV {
    /*width: 100%;*/
}

.non-compliant-container > DIV > FORM > DIV > DIV > TEXTAREA {
    /*height: 4rem !important;*/
    /*width: 100%;*/
    /*display: inline-block;*/
}




.non-compliant-container-labeltextarea {
    margin-top: 1rem;
}

.low-non-compliant-button {
    display: flex;
    flex-direction: column-reverse;
}

.audit-low-noncompliant-group {
    display: flex;
    flex-direction: column;
    /*width: 50%;*/
    /*max-width: 640px;*/
    /*min-width: 400px;*/
}



.non-compliant-comander-button {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    width: auto !important;
    align-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    background: #18f !important;
    border: solid 0px transparent !important;
}




.helper-icon {
    margin-right: 0px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 4rem;
    font-size: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

.helper-icon > SPAN {
    font-size: 2rem;
}

.helper-icon > CODE {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}


.question-save-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-right: 2rem;
    align-self: flex-start;
    margin-bottom: 1rem;
}

.question-save-button2 {
    z-index: 1 !important;
    position: relative !important;
}

.sirating-question-attachment-magnitude {
    /*background: #0acf97;*/
    color: #fff;
    border-radius: 25px;
    width: 23px;
    min-width: 23px;
    height: 23px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    position: relative;
    right: 2.5rem;
    top: 1rem;
    font-size: 0.75rem;
    padding-top: 0.175rem;
}

.srtool-attachment-magnitude {
    background: #0acf97;
    color: #fff;
    border-radius: 25px;
    width: 23px;
    min-width: 23px;
    height: 23px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    position: relative;
    right: 3rem;
    top: 10px;
    font-size: 0.9rem;
    padding-top: 0.075rem;
}


.sirating-question-attachment-attention {
    color: #f31;
    border-radius: 25px;
    width: 23px;
    height: 23px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    position: relative;
    right: 2.75rem;
    top: 0.75rem;
    font-size: 1.6rem;
    padding-top: 0.175rem;
}


.question-history-button {
    font-size: 2rem;
    color: #727cf5;
    margin-top: -10px;
}



.sirating-question-attachment-icon {
    transform: rotate(90deg);
    /*display: inline-block;*/
    font-size: 2rem;
    color: #727cf5;
    /*font-weight: bold;*/
    /*margin-top: 0.25rem;*/
    cursor: pointer;
    margin-left: 1.25rem;
    margin-top: -10px;
}


.status-ball {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    /*display: inline-block;*/
    /*left: 16px;*/
    margin-right: 10px;
    margin-top: 4px;
}


.status-ball-in-wait {
    width: 14px !important;
    height: 14px !important;
    font-size: 0.75rem;
    margin-right: 10px;
    /*margin-left: 0.5rem;*/
    margin-top: 0.15rem;
}

TEXTAREA.question-note {
    border-radius: 8px !important;
    border: solid 0px transparent !important;
    resize: none !important;
}


.question-note {
    height: 50vh !important;
    overflow: clip;
    font-style: italic;
    font-size: 1.1rem !important;
}

.question-note:hover {
    background: #0001;
    font-style: normal;
}

.question-note:active {
    color: #18f !important;
    font-style: normal;
}

.question-note:focus {
    background: #00000005 !important;
    color: #18f !important;
    font-style: normal;
    border: solid 1px #ccc !important;
}
