.srtool-edit-page-card {
    margin-bottom: 0px !important;
    background: #3331 !important;
}

.srtool-edit-page-card-header {
    cursor: pointer;
    /*background: #8881;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.srtool-edit-page-card-header:hover {
    /*background: #bdf8;*/
    background: #cbe5ff;
}


.srtool-node-edited {
    color: #aaa;
    font-style: italic;
}

.semi-visible {
    opacity: 0.25;
}

.srtool-edit-page-card-header > DIV {
    min-width: 180px;
    margin-left: 1rem;
    position: relative;
    left: 2.75rem;
}



.srtool-edit-page-card-header > DIV > BUTTON {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 22px;
}
.srtool-edit-page-card-header > DIV > I {
    font-size: 1.2rem;
    margin-right: 1.5rem;
}
.srtool-edit-page-card-header > DIV > I:hover {
    color: #000;
}

.srtool-edit-page-card-header > DIV > INPUT {
    width: 95%;
}

.srtool-edit-modal-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 22px;
}

.srtool-edit-modal-button > I {
    margin-right: 0.5rem;
}

.srtool-edit-modal-add-button {
    margin-bottom: 1rem;
}

.edit-srtool-tab {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}
.edit-srtool-tab > I {
    margin-left: 1rem;
}

.tab-active {
    font-weight: bold !important;
    border-top: solid 2px #abf !important;
    border-left: solid 2px #abf !important;
    border-right: solid 2px #abf !important;
}


.edit-question-icon-trash {

}

IMG.icon-in-srtool-edit {
    max-width: 24px;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: -5px;
}


IMG.icon-in-srtool-edit.edit,
I.edit
{
    background: #0f02;
    overflow: visible;
    box-shadow: 0px 0px 20px #0f03;
}


.icon-edit-in-srtool-editing {
    border-radius: 100px;
    background: #0d0;
    display: inline-block;
    width: 12px;
    height: 12px;
    position: relative;
    top: 10px;
    left: -36px;
}
