

.audit-question-name-text {
    width: 30%;
    min-width: 100px;
    max-width: 1200px;
    /*height: 200px*/
    margin-right: 40px;
}




.audit-non-compliant-comander-extended {
    height: 20rem !important;
}

.audit-non-compliant-comander-extended-plus {
    height: 20rem !important;
}



.non-compliant-container-select {

}

.non-compliant-container-message {

}

.non-compliant-switch {
    cursor: pointer;
}

.non-compliant-switch > LABEL {
    cursor: pointer;
    color: #2c8ef8;
}

.non-compliant-select {
    width: 13rem !important;
}


