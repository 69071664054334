/*
Policy colori
-------------------------------
primary		indigo		#727cf5
secondary	grigio		#6c757d
success		green		#0acf97
info		cyan		#39afd1
warning		gold		#ffbc00
danger		rossoChiaro	#fa5c7c
light		quasiBianco #eef2f7
dark		bluScuro	#313a46
blue					#2c8ef8
purple		viola		#6b5eae
pink					#ff679b
orange					#fd7e14
yellow					#ffbc00
teal		pisella		#02a8b5
gray					#98a6ad
gray-dark				#343a40
-------------------------------
 */



/*	@deprecated COLOR POLICY TEXT ----------------------------------------- */
.question-enabled-text {
    color: #0acf97 !important;
}

.question-avoid-text {
    color: #6c757d !important;
}

.question-disabled-text {
    color: #fa5c7c !important;
}

.question-edit-text {
    color: #727cf5 !important;
}

.question-pending-text {
    color: #39afd1 !important;
}


/*	COLOR POLICY TEXT ----------------------------------------- */
.color-policy-text-color-default {
    color: #313a46 !important;
}
.color-policy-text-color-disabled {
    color: #6c757d !important;
}
.color-policy-text-color-edit {
    color: #727cf5 !important;
}
.color-policy-text-color-complete {
    color: #39afd1 !important;
}
.color-policy-text-color-finished {
    /*color: #17589a !important;*/
    color: #11aa33 !important;
}
.color-policy-text-color-validated {
    color: #0acf97 !important;
}
.color-policy-text-color-warning {
    color: #fd7e14 !important;
}
.color-policy-text-color-lowWarning {
    color: #ffbc00 !important;
}
.color-policy-text-color-error {
    color: #fa5c7c !important;
}


.color-policy-text-color-evident {
    color: #1188ff !important;
}



/*	COLOR POLICY STATUS ----------------------------------------- */
.color-policy-background-color-default {
    background: #313a46 !important;
}
.color-policy-background-color-edit {
    background: #727cf5 !important;
}
.color-policy-background-color-complete {
    background: #39afd1 !important;
}
.color-policy-background-color-finished {
    /*background: #17589a !important;*/
    background: #11aa33 !important;
}
.color-policy-background-color-validated {
    background: #0acf97 !important;
}
.color-policy-background-color-warning {
    background: #fd7e14 !important;
}
.color-policy-background-color-lowWarning {
    background: #ffbc00 !important;
}
.color-policy-background-color-error {
    background: #fa5c7c !important;
}


/* COLOR POLICY SWITCH ------------------------------------------ */

.non-compliant-with-message-switch-enabled > LABEL {
    color: #fd7e14 !important;
}
.non-compliant-with-message-switch-enabled > LABEL:before {
    color: #fd7e14;
    background-color: #fd7e14 !important;
    border-color: #fd7e14 !important;
}

.non-compliant-low-switch-enabled > LABEL {
    color: #fa5c7c !important;
}
.non-compliant-low-switch-enabled > LABEL:before {
    color: #fa5c7c !important;
    background-color: #fa5c7c !important;
    border-color: #fa5c7c !important;
}

.non-compliant-high-switch-enabled > LABEL {
    color: #dd0000 !important;
}
.non-compliant-high-switch-enabled > LABEL:before {
    color: #dd0000 !important;
    background-color: #dd0000 !important;
    border-color: #dd0000 !important;
}



.virgin-switch-enabled > LABEL {
    color: #343a40 !important;
}
.virgin-switch-enabled > LABEL:before {
    color: #343a40 !important;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
}

.edit-switch-enabled > LABEL {
    color: #727cf5 !important;
}
.edit-switch-enabled > LABEL:before {
    color: #727cf5 !important;
    background-color: #727cf5 !important;
    border-color: #727cf5 !important;
}

.complete-switch-enabled > LABEL {
    color: #39afd1 !important;
}
.complete-switch-enabled > LABEL:before {
    color: #39afd1 !important;
    background-color: #39afd1 !important;
    border-color: #39afd1 !important;
}

.finisched-switch-enabled > LABEL {
    color: #313a46 !important;
}
.finisched-switch-enabled > LABEL:before {
    color: #313a46 !important;
    background-color: #313a46 !important;
    border-color: #313a46 !important;
}

.validated-switch-enabled > LABEL {
    color: #0acf97 !important;
}
.validated-switch-enabled > LABEL:before {
    color: #0acf97 !important;
    background-color: #0acf97 !important;
    border-color: #0acf97 !important;
}



.non-compliant-with-message-switch-disabled > LABEL:before {
    /*background: #fd7e14;*/
}

.non-compliant-high-switch-disabled > LABEL:before {
    /*background: #dd0000;*/
}

.non-compliant-low-switch-disabled > LABEL:before {
    /*border: solid 1px;*/
    /*border-color: #2c8ef8 !important;*/

}

.virgin-switch-disabled {
    /*background: #343a40;*/
}

.edit-switch-disabled {
    /*background: #727cf5;*/
}

.complete-switch-disabled {
    /*background: #39afd1;*/
}

.finisched-switch-disabled {
    /*background: #313a46;*/
}

.validated-switch-disabled {
    /*background: #0acf97;*/
}

